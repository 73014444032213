<template>
  <div
    class="tw-bg-main d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <p class="p-3 alert-danger d-inline-block fw-bolder">
      (Notify)請稍後~正在為您轉址中...
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      identity: 'customer',
      loading: false,
      lineCallBackInfo: {
        code: "",
        state: "",
      },
      redirectTo: "",
    };
  },
  created() {
    this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
    this.loading = true;
    this.dealWithLineCallBack();
    this.lineNotify();
  },
  methods: {
    // 處理 line 登入完回傳的資訊
    dealWithLineCallBack() {
      const array = window.location.search.split("&");
      array[0] = array[0].slice(1);
      // 這邊網頁板登入 query 只會有 2 個，但手機版登入會經過 APP 登入所以會有 4 個 query
      array.forEach((item) => {
        console.log(item);
        if (item.split("=")[0] === "code") {
          this.lineCallBackInfo.code = item.split("=")[1];
        } else if (item.split("=")[0] === "state") {
          this.lineCallBackInfo.state = item.split("=")[1];
        }
      });
      // 從 state 判斷完成 API之後要轉址去哪裡
      this.redirectTo = "";
      console.log(this.lineCallBackInfo.state)
      console.log(this.lineCallBackInfo.state.slice(0, 5));
      const storeId = localStorage.getItem('currentStoreId')
      if (this.lineCallBackInfo.state.slice(0, 5) === "store") {
        this.redirectTo = `/seller/store/${storeId}/social`;
      } else {
        if (this.identity === 'seller') this.redirectTo = `/user/linkAccounts`;
        else this.redirectTo = `/customer/linkAccounts`;
      }
    },
    // 執行 line notify 的 API
    lineNotify() {
      const vm = this
      const lineNotifyAPI = `${process.env.VUE_APP_API}/apps/line/notifyCallback`;
      const data = new FormData();
      data.append("code", this.lineCallBackInfo.code);
      data.append("state", this.lineCallBackInfo.state);
      $.ajax({
        type: 'POST',
        async: true,
        url: lineNotifyAPI,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$router.push(vm.redirectTo);
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          alert(err.responseJSON.message);
          vm.$router.push(vm.redirectTo);
        },
      })
    },
  },
};
</script>